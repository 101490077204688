import type { BannerItem as HomeSliderType } from '@/types'
import Link from 'next/link'
import { useRef, type FC } from 'react'
import type { Settings } from 'react-slick'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'

interface Props {
  sliders: Array<HomeSliderType>
}

export const HomeSliderV1: FC<Props> = ({ sliders }: Props) => {
  const sliderSettings: Settings = {
    autoplay: true,
    dots: true,
    autoplaySpeed: 7000,
    arrows: false,
    speed: 700,
    pauseOnHover: true,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  }
  const ref = useRef<Slider>(null)
  const chunkArray = (arr: Array<HomeSliderType>, chunkSize: number) => {
    const result = []
    for (let i = 0; i < arr?.length; i += chunkSize) {
      const chunk = arr?.slice(i, i + chunkSize)
      result.push({ idx: result.length + 1, items: chunk })
    }
    return result
  }

  const newArray: { idx: number; items: HomeSliderType[] }[] = chunkArray(
    sliders,
    3
  )

  return (
    <section
      className={`homeSlider mb-2.5 overflow-hidden w-full ${
        !sliders ? 'hidden' : ''
      }`}
    >
      {sliders ? (
        <Slider
          ref={ref}
          {...sliderSettings}
          className='max-w-screen slickHome flex'
        >
          {newArray &&
            newArray.map(
              (
                slider: { idx: number; items: HomeSliderType[] },
                index: number
              ) => (
                <div className='w-full !grid !grid-cols-2 !gap-2' key={index}>
                  <div className='w-full'>
                    {slider &&
                      slider?.items?.slice(0, 1).map((e, idx) => (
                        <div className='w-full' key={idx}>
                          <Link
                            href={e.link || '/'}
                            className='rounded overflow-hidden block outline-none h-full w-full'
                          >
                            <ImageOptimize
                              width={196}
                              height={299}
                              src={e.image}
                              alt={e.title}
                              className='w-full object-cover h-full'
                            />
                          </Link>
                        </div>
                      ))}
                  </div>
                  <div className={`grid grid-rows-2 gap-2 w-full`}>
                    {slider &&
                      slider?.items?.slice(1, 3).map((e, idx) => (
                        <Link
                          href={e.link || '/'}
                          className='rounded overflow-hidden block outline-none h-full w-full'
                          key={idx}
                        >
                          <ImageOptimize
                            width={196}
                            height={92}
                            src={e.image}
                            alt={e.title}
                            className='w-full object-cover h-full'
                          />
                        </Link>
                      ))}
                  </div>
                </div>
              )
            )}
        </Slider>
      ) : (
        ''
      )}
    </section>
  )
}
