import { PageTypeSuffix } from '@/constants'
import { type Post } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Link from 'next/link'
import ImageOptimize from '../ImageOptimize'
interface BlogCardType {
  post: Post
  className?: string
  feature?: boolean
  idx?: number
  onClick?: () => void
}

export const BlogCardV1 = ({ post, className = '', idx = 0 }: BlogCardType) => {
  return (
    <div className='max-w-[75%] flex-[0_0_75%]'>
      <div
        className={`overflow-hidden h-full rounded ${className} ${
          idx == 0 ? 'bg-white' : 'bg-primary-base'
        }`}
      >
        <div className='grid grid-cols-2 p-4 gap-1 h-full'>
          <div className='description flex flex-col gap-1'>
            <Link
              href={`/${getTranslate(post.slugs.value)}${post.slugs.postfix}${
                PageTypeSuffix.POST
              }`}
              className='block'
            >
              <h3
                className={`text-sm font-bold leading-[17px] ${
                  idx == 0 ? 'text-dark-800' : 'text-white'
                }`}
              >
                {getTranslate(post?.title)}
              </h3>
            </Link>
            <Link
              href={`/${getTranslate(post.slugs.value)}${post.slugs.postfix}${
                PageTypeSuffix.POST
              }`}
              className={`h-9 rounded w-max px-4 flex items-center justify-center font-medium mt-3 ${
                idx == 0
                  ? 'bg-primary-base text-white'
                  : 'bg-white text-dark-900'
              }`}
            >
              Xem thêm
            </Link>
          </div>
          <div className='w-full'>
            <ImageOptimize
              src={post?.images[0]}
              className='object-contain w-full h-full'
              width={404}
              height={227}
              loading='lazy'
              alt={getTranslate(post?.title)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCardV1
