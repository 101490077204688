import { useBlogHook } from '@/hooks/useBlogHook'
import Link from 'next/link'
import { useEffect } from 'react'
import BlogCardV1 from './BlogCardV1'
const BlogList = () => {
  const { getPosts, posts, setPosts } = useBlogHook()
  //   const t = useTranslations('Common')
  useEffect(() => {
    getPosts(
      {
        limit: 4,
        highlight: true,
        currentPage: 1,
      },
      {
        onSuccess: res => {
          setPosts(res.data.data)
        },
      }
    )
  }, [])
  return (
    <>
      {posts && posts.length > 0 ? (
        <section className='blogList mt-5 pl-3'>
          <div className='head-sec relative mb-2'>
            <h3 className={`text-base font-medium`}>TIN TỨC NỔI BẬT</h3>
            <Link
              href='/post'
              className='text-primary-base absolute right-3 top-[50%] flex translate-y-[-50%]'
            >
              Xem thêm
            </Link>
          </div>
          <div
            className={`listBlog flex overflow-x-auto w-full gap-x-2 mb:hiddenScrollBar`}
          >
            {posts &&
              posts?.map((e, index) => (
                <BlogCardV1 key={index} post={e} idx={index % 2 == 0 ? 0 : 1} />
              ))}
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  )
}
export default BlogList
