import type { BannerItem as HomeMiddleBanner } from '@/types'
import Link from 'next/link'
import { useMemo, type FC } from 'react'
import Slider from 'react-slick'
import ImageOptimize from '../ImageOptimize'

interface Props {
  banner: Array<HomeMiddleBanner>
  layout?: number | null
}
export const HomeBanner: FC<Props> = ({ banner, layout }: Props) => {
  const layoutOne = useMemo(() => {
    return (
      <div className='grid grid-cols-2 gap-[14px]'>
        {banner &&
          banner.slice(0, 1).map((e, index) => (
            <div className='w-full' key={index}>
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
              >
                <ImageOptimize
                  width={196}
                  height={299}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            </div>
          ))}
        <div className={`grid grid-cols-1 gap-[14px]`}>
          {banner &&
            banner.slice(1, 4).map((e, index) => (
              <div className='block' key={index}>
                <Link
                  href={e.link || '/'}
                  className='rounded overflow-hidden block outline-none h-full w-full'
                >
                  <ImageOptimize
                    width={196}
                    height={92}
                    src={e.image}
                    alt={e.title}
                    className='w-full object-cover h-full'
                  />
                </Link>
              </div>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const layoutTwo = useMemo(() => {
    return (
      <div className='grid grid-cols-2 gap-[14px]'>
        {banner &&
          banner.map((e, index) => (
            <div className='w-full' key={index}>
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
              >
                <ImageOptimize
                  width={196}
                  height={299}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            </div>
          ))}
      </div>
    )
  }, [layout])
  const layoutThree = useMemo(() => {
    if (!banner || banner.length <= 2) {
      return (
        <div className='w-full grid grid-row-2 gap-3'>
          <div className='grid grid-cols-2 gap-2'>
            {banner &&
              banner.slice(0, 2).map((e, index) => (
                <div className='w-full' key={index}>
                  <Link
                    href={e.link || '/'}
                    className='rounded overflow-hidden block outline-none h-full w-full'
                  >
                    <ImageOptimize
                      width={196}
                      height={299}
                      src={e.image}
                      alt={e.title}
                      className='w-full object-cover h-full'
                    />
                  </Link>
                </div>
              ))}
          </div>
          <div className={`grid grid-cols-3 gap-2 w-full`}>
            {banner &&
              banner.slice(2, 5).map((e, index) => (
                <Link
                  href={e.link || '/'}
                  className='rounded overflow-hidden block outline-none h-full w-full'
                  key={index}
                >
                  <ImageOptimize
                    width={196}
                    height={92}
                    src={e.image}
                    alt={e.title}
                    className='w-full object-cover h-full'
                  />
                </Link>
              ))}
          </div>
        </div>
      )
    } else {
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
      }
      return (
        <div className='relative'>
          <div className='-mx-[4px]'>
            <Slider {...settings}>
              {banner.map((e, index) => (
                <div key={index} className='px-[4px]'>
                  <Link
                    href={e.link || '/'}
                    className='block overflow-hidden rounded-lg outline-none h-full w-full'
                  >
                    <div className='relative flex h-full w-full'>
                      <ImageOptimize
                        src={e.image}
                        alt={e.title}
                        className='h-full w-full object-cover'
                        loading='lazy'
                      />
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )
    }
  }, [layout])
  const layoutFour = useMemo(() => {
    return (
      <div className='w-full flex flex-wrap'>
        <div className={`grid grid-cols-3 gap-2 w-full`}>
          {banner &&
            banner.slice(0, 3).map((e, index) => (
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
                key={index}
              >
                <ImageOptimize
                  width={196}
                  height={92}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const layoutFive = useMemo(() => {
    return (
      <div className={`grid grid-cols-3 gap-2 w-full px-2`}>
        {banner &&
          banner.slice(0, 3).map((e, index) => (
            <Link
              href={e.link || '/'}
              className='rounded overflow-hidden block outline-none h-full w-full'
              key={index}
            >
              <ImageOptimize
                width={196}
                height={92}
                src={e.image}
                alt={e.title}
                className='w-full object-cover h-full'
              />
            </Link>
          ))}
      </div>
    )
  }, [layout])
  const layoutSix = useMemo(() => {
    return (
      <div className='w-full grid grid-cols-2 gap-2'>
        <div className='w-full'>
          {banner &&
            banner.slice(0, 1).map((e, index) => (
              <div className='w-full' key={index}>
                <Link
                  href={e.link || '/'}
                  className='rounded overflow-hidden block outline-none h-full w-full'
                >
                  <ImageOptimize
                    width={196}
                    height={299}
                    src={e.image}
                    alt={e.title}
                    className='w-full object-cover h-full'
                  />
                </Link>
              </div>
            ))}
        </div>
        <div className={`grid grid-rows-2 gap-2 w-full`}>
          {banner &&
            banner.slice(1, 3).map((e, index) => (
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
                key={index}
              >
                <ImageOptimize
                  width={196}
                  height={92}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const layoutSeven = useMemo(() => {
    return (
      <div className='w-full grid grid-row-2 gap-3'>
        <div className='grid grid-cols-2 gap-2'>
          {banner &&
            banner.slice(0, 2).map((e, index) => (
              <div className='w-full' key={index}>
                <Link
                  href={e.link || '/'}
                  className='rounded overflow-hidden block outline-none h-full w-full'
                >
                  <ImageOptimize
                    width={196}
                    height={299}
                    src={e.image}
                    alt={e.title}
                    className='w-full object-cover h-full'
                  />
                </Link>
              </div>
            ))}
        </div>
        <div className={`grid grid-cols-3 gap-2 w-full`}>
          {banner &&
            banner.slice(2, 5).map((e, index) => (
              <Link
                href={e.link || '/'}
                className='rounded overflow-hidden block outline-none h-full w-full'
                key={index}
              >
                <ImageOptimize
                  width={196}
                  height={92}
                  src={e.image}
                  alt={e.title}
                  className='w-full object-cover h-full'
                />
              </Link>
            ))}
        </div>
      </div>
    )
  }, [layout])
  const checkLayoutBanner = () => {
    switch (layout) {
      case 1:
        return layoutOne
        break
      case 2:
        return layoutTwo
        break
      case 3:
        return layoutThree
        break
      case 4:
        return layoutFour
        break
      case 5:
        return layoutFive
        break
      case 6:
        return layoutSix
        break
      case 7:
        return layoutSeven
        break
      default:
        return layoutOne
        break
    }
  }
  return (
    <>
      {banner && (
        <div className={`mb-2 ${layout != 5 ? 'container' : ''}`}>
          {checkLayoutBanner()}
        </div>
      )}
    </>
  )
}
