import { Banner } from '@/components/Banner'
import BlogList from '@/components/BlogList'
import { HomeBanner } from '@/components/HomeBanner'
import { HomeSliderV1 } from '@/components/HomeSlider/HomeSliderV1'
import QuickMenu from '@/components/QuickMenu'
import ProductCardSkeleton from '@/components/Skeleton/ProductCardSkeleton'
import { BannerType, PageType, SaleChannel } from '@/constants'
import { useAuth, useNotification } from '@/containers'
import { useProductCarouselHook } from '@/hooks/useProductCarouselHook'
import { Meta } from '@/layouts/Meta'
import { Main } from '@/templates/Main'
import type { ProductCarouselResponse, Promotion, PropertyValue } from '@/types'
import {
  type BannerList,
  type Menu,
  type Province,
  type Setting,
} from '@/types'
import { getTranslate, setContext } from '@/utils/api-interceptors'
import {
  getBanner,
  getBottomMenu,
  getConfig,
  getHomepageProductCarousel,
  getKeywordFooter,
  getMenus,
  getProductTrending,
  getQuickMenu,
} from '@/utils/endpoint'
import { getCookies } from 'cookies-next'
import { type GetServerSideProps } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton'
import { v4 as uuidv4 } from 'uuid'
const ProductCarousel = dynamic(() => import('@/components/ProductCarousel'), {
  ssr: false,
})
const FlashSaleV1 = dynamic(
  () => import('@/components/FlashSale/FlashSaleV1'),
  {
    ssr: false,
  }
)
type HomeProps = {
  setting?: Setting
  promotion: Promotion
  stores?: Province[]
  banner: BannerList[]
  quickMenu: Menu[]
  brand: PropertyValue[]
}

const Home = ({ banner, quickMenu }: HomeProps) => {
  const { systemConfig } = useNotification()
  const [productCarouselData, setProductCarouselData] =
    useState<ProductCarouselResponse>()
  const [loadingProductCarousel, setLoadingProductCarousel] = useState(true)
  const { token } = useAuth()
  const router = useRouter()
  useEffect(() => {
    getHomepageProductCarousel().then(
      (res: ProductCarouselResponse | undefined) => {
        setLoadingProductCarousel(false)
        if (res) {
          return setProductCarouselData(res)
        }
      }
    )
  }, [])
  const {
    totalPages,
    productCarousel,
    currentPage,
    setCurrentPage,
    getProductCarousels,
    setLoading,
    setProductCarousel,
  } = useProductCarouselHook(productCarouselData)
  const mainBanner = banner.filter(banner => {
    return banner.type === BannerType.MAIN && banner.status === 1
  })
  const middleBanner = banner.filter(banner => {
    return banner.type === BannerType.HOME && banner.status === 1
  })
  const footerBanner = banner.filter(banner => {
    return banner.type === BannerType.HOME_FOOTER && banner.status === 1
  })

  useEffect(() => {
    const app = localStorage?.getItem('app')
    if (app === SaleChannel.B2E && !token) {
      router.push('/login')
    }
  })
  const fetchProductCarousel = () => {
    setCurrentPage(currentPage + 1)
    setLoading(true)
    if (productCarousel) {
      getProductCarousels(
        {
          limit: 2,
          sort: 'order,createdAt',
          currentPage: currentPage + 1,
        },
        {
          onSuccess: res => {
            setProductCarousel(productCarousel.concat(res.data.data))
            setLoading(false)
          },
        }
      )
    }

    setLoading(false)
  }

  return (
    <Main
      pageLayout={PageType.HOMEPAGE}
      bgColor='#E8E8E8'
      showFooter={true}
      meta={
        <Meta
          title={getTranslate(systemConfig?.seoMetaTitle)}
          description={getTranslate(systemConfig?.seoMetaDescription)}
          canonical={process.env.NEXT_PUBLIC_URL_WEBSITE}
          imageUrl={systemConfig?.seoImageDefault || ''}
          imageAlt={systemConfig?.seoImageDefault || ''}
        />
      }
    >
      <div className='pt-2 container'>
        <HomeSliderV1 sliders={mainBanner[0]?.items} />
      </div>
      <div className='mt-5'>
        <h2 className='container relative mb-2 block w-full text-sm leading-6 font-normal text-dark-400'>
          ƯU ĐÃI ĐẶC BIỆT
        </h2>
        <HomeBanner
          banner={middleBanner[0]?.items}
          layout={middleBanner[0]?.layout}
        />
        <HomeBanner
          banner={middleBanner[1]?.items}
          layout={middleBanner[1]?.layout}
        />
      </div>

      <FlashSaleV1 />
      <QuickMenu QuickMenuData={quickMenu} />
      {/* <ProductForYou /> */}
      {loadingProductCarousel ? (
        <div className='rounded-sm py-3 px-2 min-h-[364px] bg-white-500 overflow-hidden'>
          <div className='flash-sale--header mb-2 relative min-h-[40px] z-10'>
            <Skeleton duration={0.9} height={24} width={150} />
            <Skeleton duration={0.9} height={18} width={170} />
            <span className='absolute right-[4px] top-1'>
              <Skeleton duration={0.9} height={30} width={100} />
            </span>
          </div>
          <div className='flash-sale--content z-10'>
            <div className='products grid grid-fs gap-3'>
              {[...new Array(6)].map(() => (
                <ProductCardSkeleton key={uuidv4()} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {productCarousel && productCarousel.length > 0 ? (
        <InfiniteScroll
          scrollThreshold={0.3}
          dataLength={productCarousel?.length || 0}
          next={fetchProductCarousel}
          hasMore={
            currentPage !== Number(totalPages) || Number(totalPages) === 0
          }
          loader=''
        >
          {productCarousel?.map((e, i) => (
            <ProductCarousel data={e} index={i} key={i} />
          ))}
        </InfiniteScroll>
      ) : (
        ''
      )}
      <BlogList />
      <div className='mt-5'>
        <Banner dataBanner={footerBanner[0]?.items} className={''} />
      </div>
    </Main>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async ({
  req,
  res,
  locale,
}) => {
  const { token, language, messages } = await setContext(
    getCookies({ req, res }),
    {
      language: locale,
    }
  )

  const [
    promotion,
    menus,
    quickMenu,
    banner,
    systemConfig,
    keywords,
    bottomMenu,
  ] = await Promise.all([
    getProductTrending(),
    getMenus(),
    getQuickMenu(),
    getBanner(),
    getConfig(),
    getKeywordFooter(),
    getBottomMenu(),
  ])

  return {
    props: {
      messages,
      promotion,
      menus,
      quickMenu,
      banner,
      language,
      systemConfig,
      keywords,
      bottomMenu,
      token: token || null,
    },
  }
}
