/* eslint-disable @typescript-eslint/no-explicit-any */

import type { Post, PostResponse, QueryParam } from '@/types'
import { api } from '@/utils'
import { convertQueryParam } from '@/utils/next-util'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

export const useBlogHook = (postData?: PostResponse) => {
  const router = useRouter()
  const [totalProduct, setTotalProduct] = useState<number>(postData?.total || 0)
  const [totalPages, setTotalPages] = useState(postData?.pages)

  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<Post[] | null | undefined>(postData?.data)
  const [currentPage, setCurrentPage] = useState(1)

  const { mutate: getPosts } = useMutation(
    'posts',
    async (values: {
      currentPage?: number
      categoryDefault?: string
      limit?: number
      sortBy?: string
      type?: string
      sortType?: string
      highlight?: boolean
      catDisplay?: boolean
      search?: string | undefined | string[]
    }) => {
      let url = `api/posts`
      const queryParams = {
        ...router.query,
        ...(values.search ? { fullTextSearch: values.search } : {}),
        ...(values.sortType ? { sortType: values.sortType } : {}),
        ...(values.sortBy ? { sortBy: values.sortBy } : {}),
        ...(values.catDisplay ? { catDisplay: values.catDisplay } : {}),
        ...(values.categoryDefault ? { catId: values.categoryDefault } : {}),
        ...(values.highlight ? { highlight: values.highlight } : {}),
        limit: values.limit || 20,
        type: values?.type || 'news',
        page: values.currentPage ? String(values.currentPage) : '1',
      }
      if (!isEmpty(queryParams) && queryParams) {
        const param = convertQueryParam(queryParams as QueryParam)
        url = `api/posts?${param}`
      }
      return api(url, {
        method: 'GET',
        baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      })
    }
  )

  useEffect(() => {
    if (postData) {
      setPosts(postData.data)
      setTotalProduct(postData.total)
    }
  }, [postData])

  return {
    posts,
    currentPage,
    setCurrentPage,
    totalProduct,
    totalPages,
    setTotalPages,
    getPosts,
    setLoading,
    setPosts,
    loading,
  }
}
