/* eslint-disable @typescript-eslint/no-explicit-any */
import { PromotionTypes } from '@/constants'
import {
  type ProductCarouselResponse,
  type PromotionProductCarousel,
  type QueryParam,
} from '@/types'
import { api } from '@/utils'
import { convertQueryParam } from '@/utils/next-util'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

export const useProductCarouselHook = (
  productData: ProductCarouselResponse | undefined
) => {
  const { query } = useRouter()

  const [totalProductCarousel, setTotalProductCarousel] = useState<number>(
    productData?.total || 0
  )
  const [totalPages, setTotalPages] = useState(productData?.pages)

  const [loading, setLoading] = useState(false)
  const [productCarousel, setProductCarousel] = useState<
    PromotionProductCarousel[] | undefined
  >(productData?.data)
  const [currentPage, setCurrentPage] = useState(1)

  const { mutate: getProductCarousels } = useMutation(
    'productCarousel',
    async (values: {
      currentPage?: number
      categoryDefault?: string
      limit?: number
      sortBy?: string
      sort?: string
      featured?: boolean
      sortType?: string
      type?: PromotionTypes
      catDisplay?: boolean
      search?: string | undefined | string[]
    }) => {
      let url = `api/promotions?type=${PromotionTypes.LIST_HOT}`
      const queryParams = {
        ...query,
        ...(values.sortType ? { sortType: values.sortType } : {}),
        ...(values.sort ? { sort: values.sort } : {}),
        ...(values.type ? { type: values.type } : {}),
        ...(values.sortBy ? { sortBy: values.sortBy } : {}),
        limit: values.limit || 20,
        page: values.currentPage ? String(values.currentPage) : '1',
      }
      if (!isEmpty(queryParams) && queryParams) {
        const param = convertQueryParam(queryParams as QueryParam)
        url = `api/promotions?${param}&type=${PromotionTypes.LIST_HOT}`
      }
      return api(url, {
        method: 'GET',
        baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      })
    }
  )

  useEffect(() => {
    if (productData) {
      setProductCarousel(productData.data)
      setTotalProductCarousel(productData.total)
    }
  }, [productData])

  return {
    productCarousel,
    currentPage,
    setCurrentPage,
    totalProductCarousel,
    totalPages,
    setTotalPages,
    getProductCarousels,
    setLoading,
    setProductCarousel,
    loading,
  }
}
